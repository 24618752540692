var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.$store.state.appointment.isMobileServiceSectionVisible
        ? _c(
            "v-row",
            [
              _c(
                "v-row",
                { staticClass: "col-12 pb-0" },
                [
                  _c("v-col", [
                    _c("h1", { staticClass: "text-center u-green-text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("SelectServicesInPlanoAndSurroundingAreas")
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("v-row", { staticClass: "col-12 pt-0 pb-0" }, [
                _c(
                  "div",
                  { staticClass: "col-12 d-flex" },
                  [
                    _c("v-checkbox", {
                      attrs: {
                        label:
                          this.$store.state.serviceCategory.serviceSelected
                            .serviceCategory,
                      },
                      model: {
                        value: _vm.isCategorySelected,
                        callback: function ($$v) {
                          _vm.isCategorySelected = $$v
                        },
                        expression: "isCategorySelected",
                      },
                    }),
                    _vm.isCategoryHasPrice()
                      ? _c("span", { staticClass: "ml-5 mt-4 pt-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currency")(
                                  this.$store.state.serviceCategory
                                    .serviceSelected.price
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "v-row",
                { staticClass: "col-12" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "p",
                        {
                          staticClass: "u-blue-text",
                          staticStyle: { top: "-20px", position: "relative" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.selectedService.comment) + " "
                          ),
                        ]
                      ),
                      _c("v-textarea", {
                        staticClass: "col-12 col-md-6",
                        attrs: {
                          "auto-grow": "",
                          clearable: "",
                          outlined: "",
                          placeholder: this.$store.state.serviceCategory
                            .serviceSelected.isTooltip
                            ? this.$store.state.serviceCategory.serviceSelected
                                .userInstruction
                            : "",
                        },
                        model: {
                          value: _vm.comments,
                          callback: function ($$v) {
                            _vm.comments = $$v
                          },
                          expression: "comments",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "col-12 pb-0" },
                [
                  _vm.subcategories.lenght > 0
                    ? _c(
                        "v-row",
                        [
                          _c("v-col", { staticClass: "col-12" }, [
                            _c("h3", { staticClass: "u-green-text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("AdditionalServicesRequested")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.subcategories, function (item) {
                    return _c(
                      "v-row",
                      { key: item.id, staticClass: "col-12 pt-0 pb-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "col-md-6 pt-0 pb-0" },
                          [
                            _c("v-checkbox", {
                              staticClass: "mt-0 mb-0",
                              attrs: {
                                color: "primary",
                                label: item.description,
                                value: item.id,
                                disabled: item.id === 4,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.click(item.id, item.price)
                                },
                              },
                              model: {
                                value: _vm.servicesSelected,
                                callback: function ($$v) {
                                  _vm.servicesSelected = $$v
                                },
                                expression: "servicesSelected",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "col-md-6 text-right pr-0" },
                          [
                            _c("span", { staticStyle: { opacity: "0.7" } }, [
                              _c("b", [
                                _vm._v(_vm._s(_vm._f("currency")(item.price))),
                              ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c("v-row", { staticClass: "col-12" }, [_c("v-divider")], 1),
              _c(
                "v-row",
                { staticClass: "col-12" },
                [
                  _c(
                    "v-col",
                    [
                      _c("navigation", {
                        attrs: { disabled: !_vm.isCategorySelected },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }